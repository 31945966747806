import React from 'react';
import { Skeleton } from '@abdt/ornament';

import styles from './customSkeleton.module.scss'

const CustomSkeleton = (): JSX.Element => {
  return (
    <Skeleton className={styles.customSkeleton} animation="wave" variant="rect" />
  );
};

export default CustomSkeleton;