import React from 'react';

import clsx from 'clsx';

import { ModalFirstStep, ModalSecondStep } from 'core/components/index';

import { IModalContentProps } from './index.types';

import styles from './modalContent.module.scss';

const ModalContent = (props: IModalContentProps): JSX.Element => {
  const { currentGift, onClose, onClick, onCloseAndDispatch, setModalStep } =
    props;
  const [step, setStep] = React.useState<number>(1);

  const handleNextStep = (e: MouseEvent): void => {
    setStep((prev) => prev + 1);
    setModalStep(step);
  };

  const stepRenderer = React.useCallback((): JSX.Element => {
    switch (step) {
      case 1:
        return (
          <ModalFirstStep
            currentGift={currentGift}
            onCLose={onClose}
            nextStep={handleNextStep}
          />
        );
      case 2:
        return (
          <ModalSecondStep
            onCLose={onClose}
            onCloseAndDispatch={onCloseAndDispatch}
            currentGift={currentGift}
          />
        );
      default:
        return <></>;
    }
  }, [step]);

  const handleModalClassname = (): string =>
    step === 1
      ? styles.container
      : clsx(styles.container, styles.container__secondStep);

  return (
    <div onClick={onClick as any} className={handleModalClassname()}>
      {stepRenderer()}
    </div>
  );
};

export default ModalContent;
