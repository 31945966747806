import { Dispatch, useMemo } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { ActionCreatorsMapObject, AnyAction, bindActionCreators } from 'redux';

import { AppDispatch, RootState } from 'store';

export const useAppDispatch = (): Dispatch<AnyAction> =>
  useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useActions = <A, M extends ActionCreatorsMapObject<A>>(
  actions: M
): M => {
  const dispatch = useDispatch();
  return useMemo(
    () => bindActionCreators(actions, dispatch),
    [actions, dispatch]
  );
};
