import React from 'react';

import { Header } from 'core/components/ui';

import { IPageProps } from './index.types';

import styles from './page.module.scss';

const Page = (props: IPageProps) => {
  const { withHeader, children } = props;
  return (
    <>
      {withHeader && <Header />}
      <div className={styles.container}>{children}</div>
    </>
  );
};

export default Page;
