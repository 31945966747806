import React, { useMemo } from 'react';

import clsx from 'clsx';
import { push } from 'connected-react-router';
import { decode } from 'html-entities';
import typo from 'ru-typo';

import { ClientLogoDictionary, ClientLogoEnum } from 'app/constants';
import { PathsNameEnum } from 'app/singletones';
import { GiftTypes } from 'app/singletones/giftList';
import { ButtonContainer, CustomModal, CustomSvg } from 'core/common';
import { useActions, useAppDispatch, useAppSelector } from 'core/hooks';
import { giftSlice } from 'store/product/slice';
import { stopPropagation } from 'utils';
import { entityReplacerByCompanyName } from 'utils/api';

import { ICommonProductBlockProps } from './index.types';

import styles from './commonProductBlock.module.scss';

const CommonProductBlock = (props: ICommonProductBlockProps): JSX.Element => {
  const {
    client,
    title,
    productsLength,
    isInsideImageBlock,
    id,
    isGiftInfoPage,
  } = props;
  const {
    giftList: { giftsData },
    clientData: { giftStatus },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const giftSliceActions = useActions(giftSlice.actions);
  const [open, setOpen] = React.useState<boolean>(false);

  const currentGift = giftsData.data.find((gift) => gift.id === id);

  const handleClickOpen = (e: MouseEvent): void => {
    setOpen(true);
    stopPropagation(e);
  };

  const handleClickToGiftPage = (e: MouseEvent): void => {
    dispatch(push(`${PathsNameEnum.GIFT_PAGE}${id}`));
    stopPropagation(e);
  };

  const handleClickClose = (e: MouseEvent): void => {
    setOpen(false);
    stopPropagation(e);
  };

  const handleClickCloseAndDispatch = (e: MouseEvent): void => {
    setOpen(false);
    dispatch(giftSliceActions.setEmptyGifts());
    stopPropagation(e);
  };

  const handleContainerClassname = useMemo(() => {
    if (!isInsideImageBlock) {
      return clsx(styles.container, styles.container__outsideImageBlock);
    }
    return styles.container;
  }, [productsLength]);

  const handleClientClassname = useMemo((): string => {
    return !isInsideImageBlock ? styles.container__client : '';
  }, [productsLength]);

  const handleClientLotItemsClassname = useMemo((): string => {
    if (!isInsideImageBlock && productsLength) {
      return clsx(styles.container__client, styles.container__client__lotItems);
    }
    return handleClientClassname;
  }, [productsLength]);

  const rendererClientLogo = React.useCallback(() => {
    switch (client) {
      case ClientLogoEnum.Mann: {
        return <CustomSvg Svg={ClientLogoDictionary[ClientLogoEnum.Mann]} />;
      }
      case ClientLogoEnum.Megafon: {
        return <CustomSvg Svg={ClientLogoDictionary[ClientLogoEnum.Megafon]} />;
      }
      case ClientLogoEnum.MoySclad: {
        return (
          <CustomSvg Svg={ClientLogoDictionary[ClientLogoEnum.MoySclad]} />
        );
      }
      case ClientLogoEnum.Unisender: {
        return (
          <span className={styles.unisender_icon}>
            <CustomSvg Svg={ClientLogoDictionary[ClientLogoEnum.Unisender]} />
          </span>
        );
      }
      case ClientLogoEnum.Nethouse: {
        return (
          <span style={{ position: 'relative', bottom: '6px' }}>
            <img
              src={ClientLogoDictionary[ClientLogoEnum.Nethouse] as any}
              alt="logo"
            />
          </span>
        );
      }
      case ClientLogoEnum.AdvantShop: {
        return (
          <CustomSvg Svg={ClientLogoDictionary[ClientLogoEnum.AdvantShop]} />
        );
      }
      case ClientLogoEnum.Saferoute: {
        return (
          <CustomSvg Svg={ClientLogoDictionary[ClientLogoEnum.Saferoute]} />
        );
      }
      default: {
        return <>{client}</>;
      }
    }
  }, [client]);

  return (
    <div
      role="button"
      className={handleContainerClassname}
      style={isInsideImageBlock ? { flex: 1 } : undefined}
      onClick={handleClickToGiftPage as any}
    >
      <div className={handleClientLotItemsClassname}>
        {rendererClientLogo()}
      </div>
      <div
        lang="ru"
        className={
          isInsideImageBlock
            ? styles.container__title
            : clsx(styles.container__title, styles.container__title__outside)
        }
      >
        {decode(entityReplacerByCompanyName(typo(title, { hyphens: true })))}
      </div>
      {!isGiftInfoPage && (
        <>
          <ButtonContainer
            disabled={!!giftStatus.isSuccess || !!giftStatus.isAlreadyGiven}
            onCLick={handleClickOpen}
            linkTo={`${PathsNameEnum.GIFT_PAGE}${id}`}
            withMoreInfoLink
            variant="outlined"
            classNames={
              !isInsideImageBlock ? styles.container__buttonContainer : ''
            }
          >
            {currentGift?.giftType === GiftTypes.WEBSITE
              ? 'Получить подарок'
              : 'Получить промокод'}
          </ButtonContainer>
          <CustomModal
            currentGift={currentGift}
            open={open}
            handleClose={handleClickClose}
            handleCloseAndDispatch={handleClickCloseAndDispatch}
          />
        </>
      )}
    </div>
  );
};

export default CommonProductBlock;
