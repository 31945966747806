import React from 'react';
import { Link } from 'react-router-dom';

import { Breadcrumbs, SxProps, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { PathsNameEnum } from 'app/singletones';
import { useAppSelector } from 'core/hooks/redux';
import variables from 'core/style/variables.module.scss';

import { ICustomBreadcrumbsProps } from './index.types';

import styles from './customBreadcrumbs.module.scss';

const breadcrumbsStyles: SxProps<Theme> = {
  '&.MuiBreadcrumbs-root': {
    fontSize: variables.fontSizeNormal,
    lineHeight: variables.lineHeightS,
  },
  '&.MuiBreadcrumbs-separator': {
    color: variables.colorGrayDisabled,
  },
};

const breadcrumbsMobileStyles: SxProps<Theme> = {
  '&.MuiBreadcrumbs-root': {
    fontSize: '13px',
    lineHeight: '16px',
  },
  '&.MuiBreadcrumbs-separator': {
    color: variables.colorGrayDisabled,
  },
};

const CustomBreadcrumbs = (props: ICustomBreadcrumbsProps): JSX.Element => {
  const { currentGift } = props;
  const { clientId } = useAppSelector((state) => state.clientData);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getSlicedGiftName = React.useCallback((): string => {
    if (currentGift) {
      const { title } = currentGift;
      if (!isMobile) {
        return title && title.length > 40
          ? `${title.substring(0, 39)}...`
          : title;
      } else {
        return title && title.length > 30
          ? `${title.substring(0, 29)}...`
          : title;
      }
    }
    return '';
  }, [currentGift]);

  return (
    <div className={styles.container}>
      <Breadcrumbs
        sx={isMobile ? breadcrumbsMobileStyles : breadcrumbsStyles}
        separator="›"
      >
        <div>
          <Link to={`${PathsNameEnum.MAIN_PAGE}?id=${clientId}`}>Главная</Link>
        </div>
        <div className={styles.container__currentLink}>
          <Link to={`/gift/${currentGift?.id}`}>{getSlicedGiftName()}</Link>
        </div>
      </Breadcrumbs>
    </div>
  );
};

export default CustomBreadcrumbs;
