import React from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ContactFooterBlock } from 'core/components';

import styles from './footer.module.scss';

const Footer = (): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <footer className={styles.container}>
      <div className={styles.container__wrapper}>
        <p className={styles.container__article}>
          <>
            {isMobile ? (
              <>
                Есть вопросы
                <br />
                по акции?
                <br />
                Мы всегда на связи
              </>
            ) : (
              <>Есть вопросы по акции? Мы всегда на связи</>
            )}
          </>
        </p>
        <div className={styles.container__contacts}>
          <ContactFooterBlock isAbleToCall phone="8-800-200-53-03">
            <>
              Контакт-центр
              <br />
              пн–пт, 8:30 – 19:00
            </>
          </ContactFooterBlock>
          <ContactFooterBlock
            isAbleToCall
            phone="8-843-202-28-80"
            style={{ order: 3 }}
          >
            <>
              Вопросы по 115-ФЗ
              <br />
              пн–пт, 8:30 – 17:30
            </>
          </ContactFooterBlock>
          <ContactFooterBlock isAbleToCall phone="8-800-200-53-04">
            <>
              Техподдержка интернет-банка
              <br />и эквайринга:
            </>
          </ContactFooterBlock>
          {/*<div className={styles.container__contacts_toBank}>*/}
          {/*  <a>*/}
          {/*    Написать в чат*/}
          {/*    <br />*/}
          {/*    интернет-банка*/}
          {/*  </a>*/}
          {/*</div>*/}
        </div>
        <div className={styles.container__description}>
          <span>
            © 2005–2022 ПАО «АК БАРС» БАНК. Генеральная лицензия ЦБ РФ No. 2590
            от 12.08.2015 г.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
