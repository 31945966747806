// @ts-nocheck
import { Cookies } from 'react-cookie';

import axios from 'axios';
import { isNil } from 'lodash';

import {
  ICart,
  IChangeCart,
  IClientInfoProps,
  ICondition,
  IDescription,
  IProductApplications,
  Primitive,
} from 'typings/index';

//
// const { API_URL } = config;
//
// const crmId = localStorage.getItem('crmId');
//
// export const API = axios.create({
//   baseURL: API_URL,
// });

// export const decodeURLParams = (search: string): Record<string, string> => {
//   const hashes = search.slice(search.indexOf('?') + 1).split('&');
//   return hashes.reduce((params, hash) => {
//     const split = hash.indexOf('=');
//
//     if (split < 0) {
//       return Object.assign(params, {
//         [hash]: null,
//       });
//     }
//
//     const key = hash.slice(0, split);
//     const val = hash.slice(split + 1);
//
//     return Object.assign(params, { [key]: decodeURIComponent(val) });
//   }, {});
// };
//
// export const objToQs = (obj: any) => {
//   const QS = Object.keys(obj)
//     .filter((key) => obj[key] && obj[key] !== 'ALL')
//     .reduce((acc, key) => `${acc}${key}=${encodeURIComponent(obj[key])}&`, '')
//     .slice(0, -1);
//   return QS ? `?${QS}` : QS;
// };
//
// export const wait = (time: number, res = true): Promise<void> =>
//   new Promise((resolve, reject) => {
//     const fn = res ? resolve : reject.bind(null, 'error');
//     setTimeout(fn, time);
//   });

/**
 * converts payload object to form urlencoded format
 * @param payload {object} data to be encoded e.g. form data
 * @returns {string} urlencoded string
 */
export const params2form = (
  payload: Record<string, Primitive | Primitive[]>
): string =>
  Object.keys(payload)
    .filter((key) => !isNil(payload[key]))
    .map((key) => {
      if (Array.isArray(payload[key])) {
        return (payload[key] as Primitive[])
          .filter((item) => !isNil(item))
          .map(
            (item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`
          )
          .join('&');
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(
        payload[key] as Primitive
      )}`;
    })
    .join('&');

const defaultOptions = {
  path: '/',
  domain: 'akbars.ru',
  secure: true,
  maxAge: 120,
  sameSite: true,
};

export const entityReplacerByCompanyName = (str: string): string => {
  const newStr = str.split(' ').reduce((acc: string[], value) => {
    if (value.replace('&shy;', '') === 'МойСклад') {
      acc.push(value.replace('&shy;', ''));
    } else {
      acc.push(value);
    }
    return acc;
  }, []);

  return newStr.join(' ');
};

// export const setCookie = (
//   cookieName: string,
//   value: Primitive,
//   options: Record<string, unknown> = {}
// ): void => {
//   const cookie = new Cookies();
//   cookie.set(cookieName, value, { ...defaultOptions, ...options });
// };
//
// export const getCookie = (
//   cookieName: string,
//   options = {}
// ): Primitive | null => {
//   const cookie = new Cookies();
//   const value = cookie.get(cookieName);
//   if (value) {
//     cookie.remove(cookieName, { ...defaultOptions, ...options });
//     return value;
//   }
//   return null;
// };
//
// export const getCurrentMskTimeString = (): string => {
//   const date = new Date();
//   const formatOptions: Intl.DateTimeFormatOptions = {
//     year: 'numeric',
//     day: 'numeric',
//     month: 'numeric',
//     hour: 'numeric',
//     minute: 'numeric',
//     second: 'numeric',
//     fractionalSecondDigits: 3,
//     timeZone: 'Europe/Moscow',
//     timeZoneName: 'short',
//   };
//   const formatter = new Intl.DateTimeFormat('ru', formatOptions);
//
//   return formatter.format(date).replace(',', '').replace(',', '.');
// };
//
// export const convertToRublesWithThousandsSpace = (currency: number = 0) =>
//   currency?.toLocaleString('ru-RU', {
//     style: 'currency',
//     currency: 'RUB',
//     minimumFractionDigits: 0,
//     maximumFractionDigits: 1,
//   });
//
// export const priceWithDiscounts = (price: number, discounts: number = 0) =>
//   (price * (100 - discounts)) / 100;
//
// export const shortNamesOfServiceList = (descriptions: IDescription[]) =>
//   descriptions.reduce((acc: any, cur: IDescription) => {
//     return [...acc, cur.event.split('-', 1)];
//   }, []);
//
// export const declOfNum = (n: number, text_forms: string[]) => {
//   n = Math.abs(n) % 100;
//   const n1 = n % 10;
//   if (n > 10 && n < 20) {
//     return text_forms[2];
//   }
//   if (n1 > 1 && n1 < 5) {
//     return text_forms[1];
//   }
//   if (n1 === 1) {
//     return text_forms[0];
//   }
//   return text_forms[2];
// };
//
// export const formingArrayOnSameParameters = (array: any, parameter: string) =>
//   array.reduce(
//     (acc: any, cur: any) => {
//       if (!acc.hash[cur[parameter]]) {
//         acc.hash[cur[parameter]] = [cur];
//         acc.result.push(acc.hash[cur[parameter]]);
//       } else {
//         const isId = parameter === 'id';
//         acc.hash[cur[parameter]][isId ? 'count' : cur[parameter]] =
//           acc.hash[cur[parameter]].push(cur);
//       }
//
//       return acc;
//     },
//     {
//       hash: {},
//       result: [],
//     }
//   );
//
// export const preliminaryValue = (array: IProductApplications[]) =>
//   array?.reduce(
//     (sum: any, item: any) => {
//       const productWithDiscount = item.product?.availableConditions.filter(
//         (a: ICondition) => a.insurancePremium === item.price
//       )[0];
//
//       sum.price = sum.price + item.price;
//       sum.discountPrice =
//         sum.discountPrice +
//         (productWithDiscount?.discount *
//           productWithDiscount?.baseInsurancePremium) /
//           100;
//
//       return sum;
//     },
//     {
//       price: 0,
//       discountPrice: 0,
//     }
//   );
//
// export const getErrorObject = (error: Record<string, any>): any => {
//   let errorObject: any = {
//     startTime:
//       get(error, 'startTime', '') || get(error, 'config.startTime', ''),
//     errorTime: get(error, 'errorTime', ''),
//     method: get(error, 'config.method', ''),
//   };
//
//   // network errors
//   if (error.status || error.request?.status) {
//     const source = error.request || error;
//     errorObject = {
//       statusCode: get(source, 'status', ''),
//       statusText: get(source, 'statusText', 'Неизвестная ошибка'),
//       url: get(error, 'url', '') || get(error, 'config.url', ''),
//       ...errorObject,
//       response: get(source, 'responseText', ''),
//     };
//   } else {
//     // common errors
//     errorObject = {
//       name: get(error, 'name', 'Неизвестная ошибка'),
//       message: get(error, 'message', ''),
//       ...errorObject,
//       errorStack: get(error, 'stack', ''),
//     };
//   }
//   return errorObject;
// };
//
// export const prepareProductToRequestCart = (products: any) => {
//   return products.map((product: any) => ({
//     id: product.id,
//     productId: product.productId,
//     selectedProductConditionId: product.selectedProductConditionId,
//     personId: product.personId,
//     checked: product.checked,
//     removalCandidate: product.removalCandidate,
//     status: product.status,
//     insureHimself: product.insureHimself,
//   }));
// };
//
// export const getFormattedNewCart = (cart: ICart | null, products: any) => {
//   const newCart = {
//     clientCrmId: cart?.clientCrmId || null,
//     clientInfo: cart?.clientInfo || null,
//     productApplications: products,
//     persons: cart?.persons || null,
//   };
//
//   return newCart;
// };
//
// export const setUpdatedClientInfo = (
//   clientInfo: IClientInfoProps | null | undefined,
//   changedClientField: any | null
// ) => {
//   const userData = {
//     id: clientInfo?.id || '',
//     documentType: clientInfo?.documentType || 'passport',
//     passportSeries: clientInfo?.passportSeries || '',
//     passportNumber: clientInfo?.passportNumber || '',
//     lastName: clientInfo?.lastName || '',
//     firstName: clientInfo?.firstName || '',
//     patronymic: clientInfo?.patronymic || '',
//     birthDate: clientInfo?.birthDate || null,
//     registrationAddress: clientInfo?.registrationAddress || '',
//     fiasCode: clientInfo?.fiasCode || '',
//     houseNumber: clientInfo?.houseNumber || '',
//     block: clientInfo?.block || '',
//     flatNumber: clientInfo?.flatNumber || '',
//     phoneNumber: clientInfo?.phoneNumber
//       ? getMaskedPhoneNumberValue(clientInfo?.phoneNumber || '')
//       : '',
//     countryCode: clientInfo?.countryCode || '',
//     sex: clientInfo?.sex || GENDER.MALE,
//     email: clientInfo?.email || '',
//     departmentCode: clientInfo?.departmentCode || '',
//     passportIssueDate: clientInfo?.passportIssueDate || null,
//     issuedBy: clientInfo?.issuedBy || '',
//     addressesIsSame: clientInfo?.addressesIsSame,
//     actualAddress: clientInfo?.actualAddress || '',
//   };
//   return {
//     ...userData,
//     ...changedClientField,
//   };
// };
//
// export const newGuid = () => {
//   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
//     const random = (Math.random() * 16) | 0;
//     const returnValue = c === 'x' ? random : (random & 0x3) | 0x8;
//
//     return returnValue.toString(16);
//   });
// };
//
// export const getFormattedPhoneNumberValue = (value: any) =>
//   value.replace(/[^0-9]+/g, '');
//
// export const getMaskedPhoneNumberValue = (value: any) => {
//   const firstBlock = value[0];
//   const secondBlock = value.slice(1, 4);
//   const thirdBlock = value.slice(4, 7);
//   const fourthBlock = value.slice(7, 9);
//   const fifthBlock = value.slice(9);
//
//   return `+${firstBlock} (${secondBlock}) ${thirdBlock}-${fourthBlock}-${fifthBlock}`;
// };
//
// const defaultRequestOptions = {
//   credentials: 'include',
//   method: 'GET',
//   headers: {
//     'content-type': 'application/json',
//     'Access-Control-Allow-Origin': '*',
//   },
// };
//
// export function* requestToDadata(
//   url: string,
//   opt = {},
//   customEndPoint?: string
// ) {
//   const jwtToken = yield call([sessionStorage, 'getItem'], 'token');
//   const apiUrl = `${url}`;
//   const fetchUrl = opt?.query
//     ? `${apiUrl}?${objectToString(opt.query)}`
//     : apiUrl;
//   const fetchParams = {
//     ...defaultRequestOptions,
//     ...opt,
//     headers: {
//       Authorization: `Bearer ${jwtToken}`,
//       ...defaultRequestOptions.headers,
//       ...opt.headers,
//     },
//   };
//
//   if (
//     fetchParams.headers['content-type'] &&
//     fetchParams.headers['content-type'].startsWith('application/json') &&
//     fetchParams.body
//   ) {
//     fetchParams.body = JSON.stringify(fetchParams.body);
//   }
//
//   try {
//     const response = yield call(fetch, fetchUrl, fetchParams);
//     if (!response.ok) {
//       throw response;
//     }
//     const contentType = response.headers.get('content-type');
//     if (contentType && contentType.includes('application/json')) {
//       const json = yield response.json();
//       return json;
//     }
//   } catch (e) {
//     if (e.status === 401) {
//       if (url.startsWith(API)) {
//         yield put({ type: 'user/REQUEST_LOGOUT' });
//       }
//     }
//     // const statusText = e && e.status === 500 ? 'Технические неполадки' : e.statusText
//     throw Error('тз');
//   }
//   return null;
// }
//
// const objectToString = (object: any) => {
//   const objectKeysArray = Object.keys(object);
//   return objectKeysArray.reduce((acc, key, index) => {
//     const value = object[key];
//     if (value) {
//       return `${acc}${key}=${value}${
//         objectKeysArray.length === index + 1 ? '' : '&'
//       }`;
//     }
//     return acc;
//   }, '');
// };
//
// export const decodeObj = curry((keysMap, obj) =>
//   reduce(
//     keys(keysMap),
//     (acc, key) => set(acc, keysMap[key] || key, obj[key]),
//     {}
//   )
// );
//
// const addressDecoder = decodeObj(API_DICT);
//
// function setKladrRight(kladrId) {
//   const kladr = String(kladrId);
//   const lengthOfRightKladr = 19;
//   const lengthOfKladr = kladr.length;
//   if (lengthOfKladr < lengthOfRightKladr) {
//     const lengthRestOfKladr = lengthOfRightKladr - lengthOfKladr;
//     return kladr + '0'.repeat(lengthRestOfKladr);
//   }
//   return kladr;
// }
//
// export const decodeAddressData = (data) => {
//   const house = get(data, 'house', null);
//   const block = get(data, 'block', null);
//   const flat = get(data, 'flat', null);
//
//   return {
//     house: house ? `${house}` : null,
//     block: block ? `${block}` : null,
//     flat: flat ? `${flat}` : null,
//     kladr_id: data.kladr_id ? setKladrRight(data.kladr_id) : null,
//     country: RUSSIA_COUNTRY_CODE,
//   };
// };
//
// // export const fiasLevelNamesToCodes = (fiasLevelNames) =>
// //   fiasLevelNames.map((fiasLevelName) => FIAS_LEVEL[fiasLevelName]);
//
// export const getKladrIdFromFullId = (fullId: string | null) =>
//   fullId && fullId.length ? fullId.split(':')[0] : null;
//
// export const getNormalizedData = (dadataSuggestion: any) => {
//   const fullAddressWithIndex = get(dadataSuggestion, 'unrestricted_value', '');
//   const fullAddress = get(dadataSuggestion, 'value', '');
//
//   return {
//     fullAddressWithIndex,
//     fullAddress,
//     value: dadataSuggestion.value,
//     ...addressDecoder({
//       ...dadataSuggestion.data,
//       ...decodeAddressData(dadataSuggestion.data),
//     }),
//   };
// };
//
// export const getReadableFiasLevelError = (fiasLevelCodes) =>
//   `Выберите: ${fiasLevelCodesToReadable(fiasLevelCodes).join(' или ')}`;

export const getQueryParamFromURL = (param: string) => {
  const urlParam = new URLSearchParams(window.location.search);
  return urlParam.get(param) || '';
};
