import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type GiftStatusState = {
  isSuccess: boolean | null;
  isAlreadyGiven: boolean | null;
  isNoGiftAvailable: boolean | null;
  wrongEventIdPassed: boolean | null;
  webSiteLink: string | null;
};

export type TClientState = {
  clientId: string | number | null;
  giftStatus: GiftStatusState;
};

const clientSliceName = 'CLIENT';

const initialState: TClientState = {
  clientId: null,
  giftStatus: {
    isSuccess: null,
    isAlreadyGiven: null,
    isNoGiftAvailable: null,
    wrongEventIdPassed: null,
    webSiteLink: null,
  },
};

export const clientSlice = createSlice({
  name: clientSliceName,
  initialState,
  reducers: {
    saveId: (state, { payload }: PayloadAction<string>) => {
      state.clientId = payload;
      state.giftStatus = initialState.giftStatus;
    },
    error: (state) => {
      state.giftStatus = initialState.giftStatus
    },
    fetchGiftStatus: (state, { payload }: PayloadAction<GiftStatusState>) => {
      state.giftStatus = payload;
    },
  },
});

export const { saveId, fetchGiftStatus, error } = clientSlice.actions;

export default clientSlice.reducer;
