import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { history } from 'app/singletones';

import clientDataReducer from './clientData/slice';
import giftListReducer from './product/slice';

const persistConfig = {
  key: 'root',
  varsion: '1',
  storage,
};

const rootReducer = combineReducers({
  router: connectRouter(history),
  giftList: giftListReducer,
  clientData: clientDataReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
