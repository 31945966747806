import React from 'react';

import clsx from 'clsx';

import { IInformationBlockProps } from './index.types';

import styles from './informationBlock.module.scss';

const InformationBLock = (props: IInformationBlockProps) => {
  const { Image, text, title, isError = false } = props;
  return (
    <div className={styles.container}>
      <div
        className={
          !isError
            ? styles.container__image
            : clsx(styles.container__image, styles.container__image_error)
        }
      >
        {isError ? (
          <Image />
        ) : (
          <img draggable={false} src={Image} alt="success_image" />
        )}
      </div>
      <div className={styles.container__title}>
        <h4>{title}</h4>
        <span>{text}</span>
      </div>
    </div>
  );
};

export default InformationBLock;
