import React from 'react';

import { CommonProductBlock } from 'core/components';

import { IImageProductBlockProps } from './index.types';

import styles from './imageProductBlock.module.scss';

const ImageProductBlock = (props: IImageProductBlockProps): JSX.Element => {
  const { client, image, title, productsLength, id } = props;
  return (
    <div className={styles.container}>
      <div className={styles.container__image}>
        <img src={image} alt={image} />
      </div>
      <CommonProductBlock id={id} isInsideImageBlock productsLength={productsLength} title={title} client={client} />
    </div>
  );
};

export default ImageProductBlock;
