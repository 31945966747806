import React from 'react';

import { Accordion, ProductCatalog } from 'core/components';

import styles from './mainContent.module.scss';

const MainContent = (): JSX.Element => {
  return (
    <div className={styles.container}>
      <ProductCatalog title="Выберите подарок"/>
      <Accordion/>
    </div>
  );
};

export default MainContent;
