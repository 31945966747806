import React from 'react';

import { CustomAccordion } from 'core/common';

import styles from './accordion.module.scss';

const Accordion = () => {
  return (
    <div className={styles.container}>
      <div className={styles.label}>FAQ</div>
      <CustomAccordion />
    </div>
  );
};

export default Accordion;
