import React from 'react';

import { Minus, Plus } from '@abdt/icons';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  SxProps,
  Theme,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { CustomDivider } from 'core/components';
import variables from 'core/style/variables.module.scss';
import { stopPropagation } from 'utils';

import styles from './customAccordion.module.scss';

const accordionStyles: SxProps<Theme> = {
  '&.MuiAccordion-root': {
    boxShadow: 'none',
    fontSize: variables.fontSizeL,
    fontWeight: variables.fontWeigthtBold,
    lineHeight: variables.lineHeightL,
  },
  '&:before': {},
};

const accordionMobileStyles: SxProps<Theme> = {
  '&.MuiAccordion-root': {
    boxShadow: 'none',
    fontSize: variables.fontSizeM,
    fontWeight: variables.fontWeigthtBold,
    lineHeight: '24px',
  },
};

const accordionSummaryStyles: SxProps<Theme> = {
  '&.MuiAccordionSummary-root': {
    padding: '32px 0',
  },
};

const accordionSummaryMobileStyles = {
  '&.MuiAccordionSummary-root': {
    padding: '16px 0',
  },
};

const accordionDetailsStyles: SxProps<Theme> = {
  '&.MuiAccordionDetails-root': {
    fontWeight: variables.fontWeightNormal,
    padding: '0 80px 32px 0',
    lineHeight: variables.lineHeightM,
    fontSize: variables.fontSizeM,
    fontFamily: variables.fontFamilySecondary,
  },
};

const accordionDetailsMobileStyles: SxProps<Theme> = {
  '&.MuiAccordionDetails-root': {
    fontWeight: variables.fontWeightNormal,
    padding: '0 0 16px 0',
    lineHeight: '24px',
    fontSize: '16px',
    fontFamily: variables.fontFamilySecondary,
  },
};

const CustomAccordion = () => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <div className={styles.container}>
      <Accordion
        onClick={(e) => stopPropagation(e as any)}
        sx={isMobile ? accordionMobileStyles : accordionStyles}
        disableGutters
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          sx={isMobile ? accordionSummaryMobileStyles : accordionSummaryStyles}
          expandIcon={
            expanded === 'panel1' ? (
              <Minus size="large" color="black" />
            ) : (
              <Plus size="large" color="black" />
            )
          }
        >
          Могу я подарить подарок?
        </AccordionSummary>
        <AccordionDetails
          sx={isMobile ? accordionDetailsMobileStyles : accordionDetailsStyles}
        >
          Да, подарок Ваш! Распоряжайтесь им на Ваше усмотрение.
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={isMobile ? accordionMobileStyles : accordionStyles}
        disableGutters
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
      >
        <AccordionSummary
          sx={isMobile ? accordionSummaryMobileStyles : accordionSummaryStyles}
          expandIcon={
            expanded === 'panel2' ? (
              <Minus size="large" color="black" />
            ) : (
              <Plus size="large" color="black" />
            )
          }
        >
          Промокод не действует, что делать?
        </AccordionSummary>
        <AccordionDetails
          sx={isMobile ? accordionDetailsMobileStyles : accordionDetailsStyles}
        >
          Позвоните нам по номеру 8 800 200 53 03. Будем рады помочь!
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={isMobile ? accordionMobileStyles : accordionStyles}
        disableGutters
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
      >
        <AccordionSummary
          sx={isMobile ? accordionSummaryMobileStyles : accordionSummaryStyles}
          expandIcon={
            expanded === 'panel3' ? (
              <Minus size="large" color="black" />
            ) : (
              <Plus size="large" color="black" />
            )
          }
        >
          Сколько подарков я могу получить?
        </AccordionSummary>
        <AccordionDetails
          sx={isMobile ? accordionDetailsMobileStyles : accordionDetailsStyles}
        >
          Вы можете получить один из предложенных вариантов подарков, которые
          отображены на странице. Мы уверены, что Вы найдете подходящий подарок
          для развития Вашего бизнеса!
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CustomAccordion;
