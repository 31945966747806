import React from 'react';
import {ICustomSvgProps} from "./index.types";

const CustomSvg = (props: ICustomSvgProps) => {
    const {Svg} = props;

    const renderSvg = React.useCallback(
        (CategoryIcon: () => JSX.Element): JSX.Element => {
            return <CategoryIcon />;
        },
        [Svg]
    );

    return (
        <>{Svg && renderSvg(Svg as unknown as () => JSX.Element)}</>
    );
};

export default CustomSvg;