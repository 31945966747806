import React from 'react';

import { PromoContent } from 'core/components';
import { Page } from 'core/pages';

const PromoPage = () => {
  React.useEffect(() => {
    document.title = 'Страница подарка';
  }, []);
  return (
    <Page withHeader={false}>
      <PromoContent />
    </Page>
  );
};

export default PromoPage;
