import React from 'react';

import { Thanksgiving } from 'assets/MainPage/Products';
import { InformationBLock } from 'core/common';

const ThanksgivingComponent = () => {
  const textRenderer = () => {
    return (
      <>
        Надеемся, вам понравились наши подарки!
        <br />
        До встречи в новых акциях!
      </>
    );
  };
  return (
    <InformationBLock
      title="Спасибо за участие!"
      text={textRenderer()}
      Image={Thanksgiving}
    />
  );
};
export default ThanksgivingComponent;
