import React from 'react';

import { Page } from 'core/pages';
import { ErrorComponent } from '../../components/ui';

const NotFoundPage = () => {
  return (
    <Page withHeader={false}>
      <ErrorComponent title="Извините, такой страницы не существует!" text="Вернитесь на главную страницу" />
    </Page>
  );
};

export default NotFoundPage;
