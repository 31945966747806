import React from 'react';

import { FirstStepIcon } from 'assets';
import { ModalContainer } from 'core/components';
import { IModalStepProps } from 'core/components/Modal/ModalSteps/typings/index.types';
import { useActions, useAppDispatch, useAppSelector } from 'core/hooks';
import { TRequestBody, giftSlice } from 'store/product/slice';
import { stopPropagation } from 'utils';

import styles from '../styles/modalStep.module.scss';

const ModalFirstStep = (props: IModalStepProps): JSX.Element => {
  const { nextStep, onCLose, currentGift } = props;

  const dispatch = useAppDispatch();
  const giftSliceActions = useActions(giftSlice.actions);
  const { clientId } = useAppSelector((state) => state.clientData);

  const nextPage = (e: MouseEvent): void => {
    e.preventDefault();
    const requestBody: TRequestBody = {
      eventId: clientId,
      partnerGiftId: Number(currentGift?.id),
    };
    dispatch(giftSliceActions.chooseGifts(requestBody));
    if (nextStep) {
      nextStep(e);
      stopPropagation(e);
    }
  };

  const buttonContent = (): React.ReactNode => {
    return <>Получить</>;
  };

  const contentRenderer = React.useCallback((): JSX.Element => {
    return (
      <div className={styles.content__container}>
        <div className={styles.content__container_icon}>
          <FirstStepIcon />
        </div>
        <h2>{`Получить подарок от ${currentGift?.client}?`}</h2>
        <p>Вы не сможете поменять бонус после его выбора</p>
      </div>
    );
  }, [currentGift]);

  return (
    <ModalContainer
      onClose={onCLose}
      nextPage={nextPage}
      buttonContent={buttonContent}
      isFirstStep
    >
      {contentRenderer()}
    </ModalContainer>
  );
};

export default ModalFirstStep;
