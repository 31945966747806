import React from 'react';

import { Close } from '@abdt/icons';
import clsx from 'clsx';

import { CustomButton } from 'core/common';

import { IModalContainerProps } from './index.types';

import styles from './modalContainer.module.scss';

const ModalContainer = (props: IModalContainerProps): JSX.Element => {
  const {
    onClose,
    nextPage,
    children,
    contentClassname,
    isFirstStep = false,
    buttonContent,
  } = props;

  return (
    <div className={styles.container}>
      <div className={styles.container__icon}>
        <Close size="large" onClick={onClose as any} />
      </div>
      <div className={clsx(contentClassname, styles.container__content)}>
        {children}
      </div>
      <div className={styles.container__button}>
        <CustomButton
          isButtonInModal
          isGiftInfoPage
          onClick={nextPage}
          variant="contained"
        >
          {buttonContent ? buttonContent() : ''}
        </CustomButton>
        {isFirstStep && (
          <CustomButton
            isButtonInModal
            isGiftInfoPage
            onClick={onClose}
            variant="outlined"
          >
            Я ещё подумаю
          </CustomButton>
        )}
      </div>
    </div>
  );
};

export default ModalContainer;
