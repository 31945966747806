import {
  AdvantShopLogo,
  MannLogo,
  MegafonLogo,
  MoyScladLogo,
  NethouseLogo,
  SaferouteLogo,
  UnisenderLogo,
} from 'assets';

enum ClientLogoEnum {
  Mann = 'Издательство МИФ',
  Megafon = 'Компания «МегаФон»',
  MoySclad = 'СРМ «Мой склад»',
  Unisender = 'Unisender',
  Nethouse = 'Nethouse',
  AdvantShop = 'AdvantShop',
  Saferoute = 'Saferoute',
}

type TClientLogoDictionary = {
  [key: string]: JSX.Element;
};

const ClientLogoDictionary: TClientLogoDictionary = {
  [ClientLogoEnum.Mann]: MannLogo,
  [ClientLogoEnum.Megafon]: MegafonLogo,
  [ClientLogoEnum.MoySclad]: MoyScladLogo,
  [ClientLogoEnum.Unisender]: UnisenderLogo,
  [ClientLogoEnum.Nethouse]: NethouseLogo,
  [ClientLogoEnum.AdvantShop]: AdvantShopLogo,
  [ClientLogoEnum.Saferoute]: SaferouteLogo,
};

export { ClientLogoDictionary, ClientLogoEnum };
