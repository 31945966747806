import React from 'react';
import { useLocation } from 'react-router';

import { IScrollToTopProps } from './index.types';

const ScrollToTop = (props: IScrollToTopProps): JSX.Element => {
  const { children } = props;
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{children}</>;
};

export default ScrollToTop;
