import React from 'react';
import { useLocation } from 'react-router';

import clsx from 'clsx';

import { IGiftList, IGifts } from 'app/singletones/giftList';
import {
  ProductContainer,
  ThanksgivingComponent,
} from 'core/components';
import { useAppSelector } from 'core/hooks';

import { IProductCatalogProps } from './index.types';

import styles from './productCatalog.module.scss';

const ProductCatalog = (props: IProductCatalogProps): JSX.Element => {
  const { title, currentGift, isGiftInfoPage, classname } = props;
  const { giftsData } = useAppSelector(
    (state) => state.giftList,
  );
  const [giftData, setGiftData] = React.useState<IGiftList>(giftsData);
  const location = useLocation();

  React.useEffect(() => {
    const filteredGifts: IGifts[] = giftsData.data.filter(
      (gift) => gift.id !== currentGift?.id,
    );
    if (filteredGifts.length >= 4) {
      filteredGifts.length = 3;
    }
    if (isGiftInfoPage) {
      setGiftData({ ...giftsData, data: [...filteredGifts] });
    }
  }, [location.pathname]);

  return (
    <div className={clsx(styles.container, classname)}>
      <div
        className={
          isGiftInfoPage
            ? clsx(styles.container__label, styles.container__label_giftPage)
            : styles.container__label
        }
      >
        {!giftsData.isGiftTaken && <h4>{title}</h4>}
      </div>
      {giftsData.isGiftTaken ? (
        <ThanksgivingComponent />
      ) : (
        <ProductContainer
          isGiftInfoPage={isGiftInfoPage}
          data={isGiftInfoPage ? giftData : giftsData}
        />
      )}
    </div>
  );
};

export default ProductCatalog;
