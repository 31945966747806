import React from 'react';

import { SadCatIcon, SecondStepIcon } from 'assets';
import { ModalContainer } from 'core/components';
import { useAppSelector } from 'core/hooks';
import { stopPropagation } from 'utils';

import { GiftTypes } from '../../../../../app/singletones/giftList';
import styles from '../styles/modalStep.module.scss';
import { IModalStepProps } from '../typings/index.types';

const ModalSecondStep = (props: IModalStepProps): JSX.Element => {
  const { onCLose, currentGift, onCloseAndDispatch } = props;

  const { giftStatus } = useAppSelector((state) => state.clientData);

  const handleStopPropagation = (e: MouseEvent) => {
    stopPropagation(e);
  };

  const buttonContent = React.useCallback((): React.ReactNode => {
    if (giftStatus.isSuccess) {
      return (
        <a
          onClick={
            handleStopPropagation as unknown as React.MouseEventHandler<HTMLAnchorElement>
          }
          href={giftStatus.webSiteLink || ''}
          target="_blank"
          rel="noopener noreferrer"
        >
          На сайт партнера
        </a>
      );
    }

    return <div onClick={onCloseAndDispatch as any}>Ок</div>;
  }, [giftStatus]);

  const contentRenderer = React.useCallback(() => {
    if (giftStatus.isAlreadyGiven || giftStatus.isNoGiftAvailable) {
      return (
        <div className={styles.content__container}>
          <div className={styles.content__container_icon}>
            <SadCatIcon />
          </div>
          <p>Подарок уже был выбран</p>
          <span>Повторный выбор невозможен</span>
        </div>
      );
    }
    if (giftStatus.isSuccess) {
      return (
        <div className={styles.content__container}>
          <div className={styles.content__container_icon}>
            <SecondStepIcon />
          </div>
          <p>Поздравляем, подарок ваш!</p>
          <span>
            {currentGift?.giftType === GiftTypes.WEBSITE ? (
              <>
                Для получения подарка перейдите на страницу партнера. Отправили
                вам ссылку по СМС, чтобы вы могли забрать его в любое время.
                Приятного использования!
              </>
            ) : (
              <>
                Для получения подарка перейдите на страницу партнера. А чтобы
                код и ссылка на подарок не потерялись, мы отправили их по СМС.
                Приятного использования!
              </>
            )}
          </span>
        </div>
      );
    }
    return <></>;
  }, [giftStatus]);

  return (
    <ModalContainer
      contentClassname={styles.secondStep}
      isFirstStep={false}
      onClose={onCloseAndDispatch}
      buttonContent={buttonContent}
    >
      {contentRenderer()}
    </ModalContainer>
  );
};

export default ModalSecondStep;
