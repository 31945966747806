import React from 'react';
import { CustomSkeleton } from 'core/common';

import styles from './skeletonContainer.module.scss';
import { useWindowSize } from 'core/hooks';

const SkeletonContainer = () => {
  const [_, width] = useWindowSize();

  return (
    <div className={styles.skeletonContainer_container}>
      <CustomSkeleton/>
      <CustomSkeleton/>
      {width > 1280 && <CustomSkeleton/>}
    </div>
  );
};

export default SkeletonContainer;