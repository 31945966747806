import { Gift } from 'assets/MainPage/Products';

export interface IGiftInfo {
  title: string;
  description: string;
  fullDescription: string;
}

export enum GiftTypes {
  PROMOCODE = 'promocode',
  WEBSITE = 'website',
}

type TGiftType = GiftTypes.PROMOCODE | GiftTypes.WEBSITE;

export interface IGifts {
  id: string | number;
  image: typeof Image;
  title: string;
  client: string;
  giftType: TGiftType;
  isBigImage: boolean;
  giftInfo: IGiftInfo;
}

export interface IGiftList {
  dataLength: number;
  isGiftTaken: boolean;
  data: IGifts[];
}

// export const giftList: IGiftList = {
//   dataLength: 5,
//   isGiftTaken: false,
//   data: [
//     {
//       id: '1',
//       title: 'Бронзовый номер «8 800»‎ в подарок',
//       client: 'Компания «МегаФон»',
//       image: Gift,
//       isBigImage: false,
//       giftType: GiftTypes.PROMOCODE,
//       giftInfo: {
//         title: 'Специальное предложение от мегафона для клиентов Ак Барс Банка',
//         description:
//           'Подключите «8 800»‎ — горячую линию для ваших клиентов — и получите номер из категории «Бронзовый»‎ в подарок урааа',
//         fullDescription: 'https://google.com',
//       },
//     },
//     {
//       id: '2',
//       title: 'Бронзовый номер «8 800»‎ в подарок',
//       client: 'Saferoute',
//       image: Gift,
//       isBigImage: false,
//       giftType: GiftTypes.PROMOCODE,
//       giftInfo: {
//         title: 'Специальное предложение от мегафона для клиентов Ак Барс Банка',
//         description:
//           'Подключите «8 800»‎ — горячую линию для ваших клиентов — и получите номер из категории «Бронзовый»‎ в подарок урааа',
//         fullDescription: 'https://google.com',
//       },
//     },
//     {
//       id: '3',
//       title: '3 месяца бесплатно на тарифе «Базовый» с дополнительными опциями',
//       client: 'МойСклад',
//       image: Gift,
//       isBigImage: false,
//       giftType: GiftTypes.PROMOCODE,
//       giftInfo: {
//         fullDescription: '',
//         title: 'Специальное предложение от мегафона для клиентов Ак Барс Банка',
//         description:
//           'Подключите «8 800» — горячую линию для ваших клиентов — и получите номер из категории «Бронзовый» в подарок',
//       },
//     },
//     {
//       id: '4',
//       title: 'Бронзовый номер «8 800» в подарок',
//       client: 'Мегафон',
//       image: Gift,
//       isBigImage: false,
//       giftType: GiftTypes.PROMOCODE,
//       giftInfo: {
//         fullDescription: '',
//         title: 'Специальное предложение от мегафона для клиентов Ак Барс Банка',
//         description:
//           'Подключите «8 800» — горячую линию для ваших клиентов — и получите номер из категории «Бронзовый» в подарок',
//       },
//     },
//     {
//       id: '5',
//       title: '3 месяца бесплатно на тарифе "Базовый" с дополнительными опциями',
//       client: 'МойСклад',
//       image: Gift,
//       isBigImage: false,
//       giftType: GiftTypes.PROMOCODE,
//       giftInfo: {
//         fullDescription: '',
//         title: 'Специальное предложение от мегафона для клиентов Ак Барс Банка',
//         description:
//           'Подключите «8 800» — горячую линию для ваших клиентов — и получите номер из категории «Бронзовый» в подарок',
//       },
//     },
//     {
//       id: '6',
//       title: '3 месяца бесплатно на тарифе "Базовый" с дополнительными опциями',
//       client: 'МойСклад',
//       image: Gift,
//       isBigImage: false,
//       giftType: GiftTypes.PROMOCODE,
//       giftInfo: {
//         fullDescription: '',
//         title: 'Специальное предложение от мегафона для клиентов Ак Барс Банка',
//         description:
//           'Подключите «8 800» — горячую линию для ваших клиентов — и получите номер из категории «Бронзовый» в подарок',
//       },
//     },
//     {
//       id: '7',
//       title: '3 месяца бесплатно на тарифе "Базовый" с дополнительными опциями',
//       client: 'МойСклад',
//       image: Gift,
//       isBigImage: false,
//       giftType: GiftTypes.PROMOCODE,
//       giftInfo: {
//         fullDescription: '',
//         title: 'Специальное предложение от мегафона для клиентов Ак Барс Банка',
//         description:
//           'Подключите «8 800» — горячую линию для ваших клиентов — и получите номер из категории «Бронзовый» в подарок',
//       },
//     },
//   ],
// };
