import React, { useLayoutEffect } from 'react';

import clsx from 'clsx';

import { Gifts, HeaderGreenElement } from 'assets';

import styles from './header.module.scss';

const Header = (): JSX.Element => {
  const [loaded, setLoaded] = React.useState<boolean>(false);

  useLayoutEffect(() => {
    setLoaded(true);
  });

  return (
    <div className={styles.container}>
      <div className={styles.container__wrapper}>
        <div className={styles.container__content}>
          <div className={styles.container__content_title}>
            <p>Вам подарок!</p>
            <span>
              Хотим сказать «спасибо» за то, что выбрали нас: выберите любой
              бонус от наших партнеров
            </span>
          </div>
        </div>
        <div className={styles.container__background}>
          <div className={styles.background__element}>
            <HeaderGreenElement />
          </div>
          <div
            className={clsx(
              styles.background__element,
              styles.background__giftsElement
            )}
          >
            {loaded && (
              <img
                width={553}
                height={510}
                loading="eager"
                draggable={false}
                src={Gifts}
                alt="gifts"
                onLoad={() => setLoaded(true)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
