import axios, { AxiosResponse } from 'axios';

import { Primitive } from 'typings';
import { params2form } from 'utils/api';

const API_URL = process.env.REACT_APP_ENV_API_ENDPOINT;

const LOCAL_URL = 'https://service-backend-giftbox.dev.akbars.ru';

const server = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':
      'Origin, X-Requested-With, Content-Type, Accept',
  },
});

const generateAPIParams = (
  options: Record<string, Primitive | Primitive[]> | null
) => {
  const headers: any = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
    'Access-Control-Allow-Headers':
      'X-Requested-With, content-type, Authorization',
    'Access-Control-Allow-Credentials': 'true',
  };
  const query = options ? `?${params2form(options)}` : '';
  return { headers, query };
};

export class Api {
  static getFromAPI(
    endpoint: string,
    options: Record<string, Primitive | Primitive[]> | null
  ): Promise<AxiosResponse<unknown>> {
    const { headers, query } = generateAPIParams(options);
    return server.get(`${endpoint}${query}`, { headers });
  }

  static postFromAPI(
    body: Record<string, Primitive | Primitive[]>,
    endpoint: string,
    options?: Record<string, Primitive | Primitive[]> | null
  ): Promise<AxiosResponse<unknown>> {
    const { headers, query } = generateAPIParams(options!);
    return server.post(`${endpoint}${query}`, body, { headers });
  }
}
