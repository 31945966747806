import { Dispatch } from 'react';

import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import {
  StrictEffect,
  call,
  put,
  select,
  takeLeading,
} from 'redux-saga/effects';

import { Api } from 'app/api';
import { IGiftList } from 'app/singletones/giftList';
import {
  GiftStatusState,
  TClientState,
  fetchGiftStatus,
  error as giftStatusError
} from 'store/clientData/slice';
import {
  TGiftState,
  TRequestBody,
  chooseGifts,
  fetchGifts,
  initialize,
  onError,
} from 'store/product/slice';
import { Primitive } from 'typings';
import { getQueryParamFromURL } from 'utils/api';

function* getGiftListSaga(): Generator<StrictEffect, void> {
  const id = getQueryParamFromURL('id');
  const guidEndpoint = '/Landing/PartnerCatalogue';
  const endpoint = '/Landing/PartnerCatalogueObsolete';
  try {
    const { data } = (yield call(
      Api.getFromAPI,
      Number(id) ? endpoint : guidEndpoint,
      {
        id,
      }
    )) as AxiosResponse<IGiftList>;
    const { giftsData } = (yield select(
      (state) => state.giftList
    )) as TGiftState;
      yield put(fetchGifts(data));
  } catch (e) {
    console.error(e);
    const error = e as AxiosError;
    yield put(onError(error.message));
    yield put(giftStatusError());
  }
}

function* chooseGiftSaga({
  payload,
}: PayloadAction<TRequestBody>): Generator<StrictEffect, void> {
  try {
    const guidEndpoint = '/Landing/ChooseGift';
    const endpoint = '/Landing/ChooseGiftObsolete';
    const clientData = (yield select(
      (state) => state.clientData
    )) as TClientState;
    const { data } = (yield call(
      Api.postFromAPI,
      payload as Record<string, Primitive | Primitive[]>,
      Number(clientData.clientId) ? endpoint : guidEndpoint
    )) as AxiosResponse<GiftStatusState>;
    yield put(fetchGiftStatus(data));
  } catch (e) {
    console.error(e);
  }
}

export function* watchGetGiftListSaga(dispatch: Dispatch<any>): SagaIterator {
  yield takeLeading(initialize.type, getGiftListSaga);
  yield takeLeading(chooseGifts.type, chooseGiftSaga);
}
