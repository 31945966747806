import React from 'react';

import { IContactFooterBlockProps } from './index.types';

import styles from './contactFooterBlock.module.scss';

const ContactFooterBlock = (props: IContactFooterBlockProps): JSX.Element => {
  const { children, phone, isAbleToCall, style } = props;
  return (
    <div style={style} className={styles.container}>
      <span className={styles.container__label}>{children}</span>
      <div className={styles.container__phone}>
        <a href={isAbleToCall ? `tel:${phone}` : undefined}>{phone}</a>
      </div>
    </div>
  );
};

export default ContactFooterBlock;
