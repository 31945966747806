import React from 'react';
import { useLocation } from 'react-router-dom';

import '@abdt/fonts';

import { Footer, HeaderLogo, Routes } from 'core/components';
import { useActions, useAppDispatch, useAppSelector } from 'core/hooks';
import 'core/style';
import { clientSlice } from 'store/clientData/slice';
import {giftSlice} from 'store/product/slice'
import { getQueryParamFromURL } from 'utils/api';

function App() {
  const location = useLocation();
  const clientSliceActions = useActions(clientSlice.actions);
  const giftSliceActions = useActions(giftSlice.actions)
  const {clientId} = useAppSelector(state => state.clientData)
  const dispatch = useAppDispatch();
  const id = getQueryParamFromURL('id');

  React.useEffect(() => {
    if (id) {
      if (id !== clientId) {
        dispatch(giftSliceActions.initialize());
      }
        dispatch(clientSliceActions.saveId(id));
    }
  }, [id]);

  return (
    <>
      <HeaderLogo location={location.pathname} />
      <Routes />
      <Footer />
    </>
  );
}

export default App;
