import React from 'react';

import { GiftTypes } from 'app/singletones/giftList';
import { ButtonContainer, CustomModal } from 'core/common';
import { useActions, useAppDispatch, useAppSelector } from 'core/hooks';
import { giftSlice } from 'store/product/slice';

import { IPromoInfoProps } from './index.types';

import styles from './promoInfo.module.scss';

const PromoInfo = (props: IPromoInfoProps): JSX.Element => {
  const { currentGift } = props;
  const [open, setOpen] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const giftSliceActions = useActions(giftSlice.actions);
  const { giftStatus } = useAppSelector((state) => state.clientData);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleClickCloseAndDispatch = (e: MouseEvent): void => {
    setOpen((prev) => {
      dispatch(giftSliceActions.setEmptyGifts());
      return false;
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.container__titleBlock}>
        <div className={styles.container__titleBlock_title}>
          <p>{currentGift?.giftInfo?.title}</p>
        </div>
        <ButtonContainer
          disabled={!!giftStatus.isSuccess || !!giftStatus.isAlreadyGiven}
          onCLick={handleClickOpen}
          isGiftInfoPage
          classNames={styles.container__titleBlock_button}
          withMoreInfoLink={false}
        >
          {currentGift?.giftType === GiftTypes.WEBSITE
            ? 'Получить подарок'
            : 'Получить промокод'}
        </ButtonContainer>
        <CustomModal
          currentGift={currentGift}
          open={open}
          handleClose={handleClickClose}
          handleCloseAndDispatch={handleClickCloseAndDispatch}
        />
      </div>
      <div className={styles.container__contentBlock}>
        <div className={styles.container__contentBlock_description}>
          <p>{currentGift?.giftInfo?.description}</p>
          <p className={styles.container__fullDescription}>
            {currentGift?.giftInfo?.fullDescription}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PromoInfo;
