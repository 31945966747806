import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type TLocations = {
  pathname: string;
  search: string;
  hash: string;
};

function getLocationId({ pathname, search, hash }: TLocations) {
  return `${pathname}${search ? `?${search}` : ''}${hash ? `#${hash}` : ''}`;
}

export const useLocationBlocker = () => {
  const history = useHistory();

  useEffect(() => {
    history.block((location: TLocations, action: any): any => {
      return (
        action !== 'PUSH' ||
        getLocationId(location) !== getLocationId(history.location)
      );
    });
  }, []);
};

export const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => {
    // eslint-disable-next-line no-new
    new URLSearchParams(search);
  }, [search]);
};
