import React from 'react';

import clsx from 'clsx';

import { PathsNameEnum } from 'app/singletones';
import { AkbarsBrand, AkbarsLogo } from 'assets/MainPage/Header';
import { CustomButton } from 'core/common';
import { useAppSelector } from 'core/hooks';

import { IHeaderLogoProps } from './index.types';

import styles from './headerLogo.module.scss';

const HeaderLogo = (props: IHeaderLogoProps) => {
  const { location } = props;
  const {
    giftsData: { isGiftTaken },
    errors,
  } = useAppSelector((state) => state.giftList);
  const getHeaderClassname = (): string => {
    if (isGiftTaken || errors) {
      return clsx(styles.container, styles.container__giftPage);
    }
    return location === PathsNameEnum.MAIN_PAGE
      ? styles.container
      : clsx(styles.container, styles.container__giftPage);
  };
  return (
    <header className={getHeaderClassname()}>
      <div className={styles.container__logo}>
        <div className={styles.container__icon_container}>
          <AkbarsBrand />
        </div>
        <div className={styles.container__icon_container}>
          <AkbarsLogo />
        </div>
      </div>
      <div>
        {/*<CustomButton buttonWidth="142px" variant="outlined">*/}
        {/*  Об акции*/}
        {/*</CustomButton>*/}
      </div>
    </header>
  );
};

export default HeaderLogo;
