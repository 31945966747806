import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';

import { history, sagaMiddleware } from 'app/singletones';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const middlewares = [sagaMiddleware, routerMiddleware(history)];

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    }),
    ...middlewares,
  ],
});

sagaMiddleware.run(rootSaga, store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export default store;
