import React from 'react';

import { Button, SxProps, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import variables from 'core/style/variables.module.scss';

import { ICustomButtonProps } from './index.types';

const buttonStyles: SxProps<Theme> = {
  '&.MuiButton-root': {
    color: '#FFFFFF',
    textTransform: 'none',
    borderRadius: '4px',
    fontWeight: variables.fontWeightNormal,
    fontFamily: variables.fontFamilySecondary,
    fontSize: variables.fontSizeNormal,
    minWidth: '180px',
    height: '48px',
    lineHeight: variables.lineHeightS,
    letterSpacing: '0.004em',
  },
  '&.MuiButton-contained': {
    backgroundColor: variables.colorGreen,
    lineHeight: variables.lineHeightM,
    '&:hover': {
      backgroundColor: variables.colorStrongGreen,
    },
  },
  '&.MuiButton-outlined': {
    color: variables.colorGreen,
    borderColor: variables.colorGreen,
    '&:hover': {
      backgroundColor: variables.colorLightGreen,
    },
  },
};

const buttonGiftPageStyle: SxProps<Theme> = {
  '&.MuiButton-root': {
    minWidth: '226px',
    height: '64px',
    fontSize: variables.fontSizeM,
    fontWeight: variables.fontWeightNormal,
    textTransform: 'none',
    letterSpacing: '0.001em',
  },
};

const buttonGiftPageMobileStyle: SxProps<Theme> = {
  height: '48px !important',
  fontSize: '14px !important',
};

const CustomButton = (props: ICustomButtonProps): JSX.Element => {
  const {
    children,
    onClick,
    variant,
    isGiftInfoPage,
    isButtonInModal,
    sx,
    buttonWidth,
    disabled,
  } = props;
  const [buttonStyle, setButtonStyle] =
    React.useState<SxProps<Theme>>(buttonStyles);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    if (isGiftInfoPage) {
      if (isMobile) {
        setButtonStyle({ ...buttonStyles, ...buttonGiftPageMobileStyle });
      } else {
        setButtonStyle({ ...buttonStyles, ...buttonGiftPageStyle });
      }
    }
    if (sx) {
      setButtonStyle({ ...(sx as SxProps<Theme>), ...buttonStyles });
    }
  }, [isGiftInfoPage, sx]);

  const handleButtonFontSize = (): string => {
    if (isMobile) {
      if (isButtonInModal) {
        return '14px';
      } else {
        return '12px';
      }
    }
    return '';
  };

  const handleButtonWidth = (): string => {
    if (isButtonInModal) {
      if (isMobile) {
        return '100%';
      }
    }
    return '';
  };

  return (
    <Button
      fullWidth={isMobile}
      disabled={disabled}
      disableElevation
      style={{
        width: handleButtonWidth(),
        minWidth: buttonWidth || '',
        height: isMobile && !isButtonInModal ? '32px' : '',
        fontSize: handleButtonFontSize(),
      }}
      onClick={onClick}
      sx={isGiftInfoPage ? buttonStyle : buttonStyles || sx}
      variant={variant}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
