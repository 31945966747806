import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { ConnectedRouter } from 'connected-react-router';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import App from './app/App';
import { history } from './app/singletones';
import store from './store';

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

const persistor = persistStore(store);

const root = ReactDOM.createRoot(MOUNT_NODE);
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);
