import React from 'react';

import { ErrorIcon } from 'assets';
import { InformationBLock } from 'core/common';

type Props = {
  title?: string,
  text?: string,
  customIcon?: string
}

const ErrorComponent = ({title, text, customIcon}: Props) => {
  return (
    <InformationBLock
      title={title || "Каталог подарков недоступен"}
      text={text || "Выбор подарка недоступен"}
      Image={customIcon || ErrorIcon}
      isError
    />
  );
};

export default ErrorComponent;
