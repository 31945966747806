import React from 'react';
import { useParams } from 'react-router';

import clsx from 'clsx';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { CustomBreadcrumbs, CustomButton, CustomDivider } from 'core/common';
import { ErrorComponent, ProductCatalog, PromoInfo } from 'core/components';
import { useAppSelector } from 'core/hooks';
import { TRouteUrlParams } from 'typings';

import styles from './promoContent.module.scss';
import { PathsNameEnum } from 'app/singletones/paths';
import { Link } from 'react-router-dom';

const dividerStyles = {
  paddingTop: '80px',
};

const dividerMobileStyles = {
  paddingTop: '104px',
};

const PromoContent = (): JSX.Element => {
  const { giftId } = useParams<TRouteUrlParams>();
  const { giftsData } = useAppSelector((state) => state.giftList);
  const { clientId } = useAppSelector((state) => state.clientData);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const currentGift = React.useMemo(() => {
    return giftsData.data.find((gift) => gift.id === +giftId!);
  }, [giftId]);

  return (
    <>
      {currentGift ? (
        <div className={styles.container}>
          <div className={clsx(styles.container, styles.container__infoBlock)}>
            <CustomBreadcrumbs currentGift={currentGift}/>
            <PromoInfo currentGift={currentGift}/>
          </div>
          <CustomDivider
            variant="middle"
            customSx={isMobile ? dividerMobileStyles : dividerStyles}
          />
          <ProductCatalog
            classname={styles.container__productBlock}
            isGiftInfoPage
            currentGift={currentGift}
            title="Другие подарки"
          />
        </div>
      ) : (
        <div className={styles.errorContainer}>
          <ErrorComponent title="Такого подарка не существует!"/>
           <CustomButton isGiftInfoPage variant="outlined"><Link to={`${PathsNameEnum.MAIN_PAGE}?id=${clientId}`}>Вернуться на главную</Link></CustomButton>
        </div>
      )}
    </>
  );
};

export default PromoContent;
