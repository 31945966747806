import React from 'react';

import { Divider, SxProps, Theme } from '@mui/material';

import { ICustomDividerProps } from './index.types';

const dividerStyles: SxProps<Theme> = {
  width: '100%',
};

const CustomDivider = (props: ICustomDividerProps) => {
  const { customSx, sx } = props;
  return (
    <Divider
      sx={customSx ? { ...dividerStyles, ...customSx } : dividerStyles}
      {...props}
    />
  );
};

export default CustomDivider;
