import React, { useCallback, useMemo } from 'react';

import clsx from 'clsx';
import { useKeenSlider } from 'keen-slider/react';

import { IGifts } from 'app/singletones/giftList';
import {
  CommonProductBlock,
  ImageProductBlock,
  MutationPlugin, SkeletonContainer,
} from 'core/components';
import { useAppSelector, useWindowSize } from 'core/hooks';

import { IProductContainerProps } from './index.types';

import styles from './productContainer.module.scss';

const ProductContainer = (props: IProductContainerProps): JSX.Element => {
  const {isLoading} = useAppSelector(state => state.giftList)
  const { data, isGiftInfoPage } = props;
  const [_, width] = useWindowSize();
  const [sliderRef, slider] = useKeenSlider(
    {
      // loop: true,
      mode: 'free-snap',
      breakpoints: {
        '(max-width: 600px)': {
          slides: {
            perView: 2,
            spacing: 10,
          },
        },
        '(max-width: 425px)': {
          slides: {
            perView: 1.7,
            spacing: 12,
          },
        },
        '(max-width: 375px)': {
          slides: {
            perView: 1.4,
            spacing: 10,
          },
        },
        '(max-width: 320px)': {
          slides: {
            perView: 1.3,
            spacing: 12,
          },
        },
      },
    },
    [MutationPlugin]
  );
  const currentProductsLength: number | string = useMemo(() => {
    return data.dataLength;
  }, [data.dataLength]);

  const productsRenderer = useCallback(
    (item: IGifts, index: number): JSX.Element => {
      if (item.isBigImage) {
        return <ImageProductBlock key={index} {...item} />;
      }
      return (
        <div className="keen-slider__slide">
          <CommonProductBlock
            isGiftInfoPage={isGiftInfoPage}
            productsLength={currentProductsLength}
            key={Number(item.id)}
            {...item}
          />
        </div>
      );
    },
    [currentProductsLength]
  );

  if (isLoading) {
    return <SkeletonContainer />
  }

  return (
    <>
      {width <= 600 ? (
        <div ref={sliderRef} className="keen-slider">
          {data.data?.map((item: IGifts, index: number) =>
            productsRenderer(item, index)
          )}
        </div>
      ) : (
        <div
          className={
            currentProductsLength === 4
              ? clsx(styles.container, styles.container__fourItems)
              : styles.container
          }
        >
          {data.data?.map((item: IGifts, index: number) =>
            productsRenderer(item, index)
          )}
        </div>
      )}
    </>
  );
};

export default ProductContainer;
