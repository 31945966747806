import React from 'react';

import { MainContent } from 'core/components/Main';
import { ErrorComponent } from 'core/components/ui';
import { useActions, useAppDispatch, useAppSelector } from 'core/hooks';
import { Page } from 'core/pages';
import { giftSlice } from '../../../store/product/slice';

const MainPage = () => {
  const { giftsData, errors } = useAppSelector((state) => state.giftList);
  const dispatch = useAppDispatch();
  const giftSliceActions = useActions(giftSlice.actions);

  React.useEffect(() => {
    document.title = 'Главная страница';
  }, []);

  React.useEffect(() => {
    dispatch(giftSliceActions.initialize());
  }, []);
  return (
    <Page withHeader={!giftsData.isGiftTaken && !errors}>
      {errors ? <ErrorComponent /> : <MainContent />}
    </Page>
  );
};

export default MainPage;
