import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ScrollToTop } from 'core/common';
import { useLocationBlocker } from 'core/hooks';
import { MainPage, PromoPage, NotFoundPage } from 'core/pages';

//todo выяснить почему возникает TypeError

// const LoadableMainPage = Loadable({
//   loader: () =>
//     import(/* webpackChunkName: "dashboardChunk" */ 'core/pages/MainPage'),
//   loading: LoadingPlug,
//   // webpack: () => [require.resolve('core/pages/MainPage')],
// });

const Routes = (): JSX.Element => {
  useLocationBlocker();
  return (
    <ScrollToTop>
      <Switch>
        <Route exact path="/" component={MainPage} />
        <Route exact path="/gift/:giftId" component={PromoPage} />
        <Route exact path="/gift" component={NotFoundPage} />
      </Switch>
    </ScrollToTop>
  );
};

export default Routes;
