import React from 'react';

import { Dialog } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { CustomModalTransition, ModalContent } from 'core/components';
import { stopPropagation } from 'utils';

import { ICustomModalProps } from './index.types';

const modalStyles: React.CSSProperties = {
  borderRadius: '12px',
};

const smModalStyles: React.CSSProperties = {
  position: 'absolute',
  bottom: 0,
  height: '448px',
  borderRadius: '12px 12px 0 0',
  alignItems: 'center',
  // maxWidth: '600px',
};

const handleStopPropagation = (e: MouseEvent) => {
  stopPropagation(e);
};

const CustomModal = (props: ICustomModalProps): JSX.Element => {
  const { handleClose, open, currentGift, handleCloseAndDispatch } = props;
  const [modalStep, setModalStep] = React.useState<number>(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog
      PaperProps={{ style: isMobile ? smModalStyles : modalStyles }}
      open={open}
      fullScreen={isMobile}
      TransitionComponent={isMobile ? CustomModalTransition : undefined}
      onClose={modalStep === 0 ? handleClose : handleCloseAndDispatch}
    >
      <ModalContent
        setModalStep={setModalStep}
        onClick={handleStopPropagation}
        onClose={modalStep === 0 ? handleClose : handleCloseAndDispatch}
        onCloseAndDispatch={handleCloseAndDispatch}
        currentGift={currentGift}
      />
    </Dialog>
  );
};

export default CustomModal;
