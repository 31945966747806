import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IGiftList } from 'app/singletones/giftList';

export type TRequestBody = {
  eventId: string | number | null;
  partnerGiftId: number;
};

export type TGiftState = {
  isInitialized: boolean;
  isLoading: boolean;
  errors: string | null;
  giftsData: IGiftList;
};

const giftSliceName = 'GIFT';

const initialState: TGiftState = {
  isInitialized: false,
  isLoading: false,
  errors: null,
  giftsData: {
    data: [],
    dataLength: 0,
    isGiftTaken: false,
  },
};

export const giftSlice = createSlice({
  name: giftSliceName,
  initialState,
  reducers: {
    initialize: (state) => {
      state.isInitialized = true;
      state.isLoading = true;
    },
    onError: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload;
      state.isLoading = false;
      state.isInitialized = false;
      state.giftsData = initialState.giftsData
    },
    fetchGifts: (state, { payload }: PayloadAction<IGiftList>) => {
      state.giftsData = payload;
      state.errors = initialState.errors;
      state.isLoading = false;
    },
    chooseGifts: (state, { payload }: PayloadAction<TRequestBody>) => {
      // state.giftsData.isGiftTaken = true;
    },
    setEmptyGifts: (state) => {
      // state.giftsData = initialState.giftsData;
      state.giftsData.isGiftTaken = true;
    },
  },
});

export const { fetchGifts, initialize, chooseGifts, setEmptyGifts, onError } =
  giftSlice.actions;

export default giftSlice.reducer;
